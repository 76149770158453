import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ConfigurationState } from '../../../app-shared-elements/_store/states/configuration.state';
import { Observable } from 'rxjs';
import { ConfigurationServerInterface } from '../../../app-shared-elements/_interfaces/configuration-server.interface';
import { ServerNamesEnum } from '../../../app-shared-elements/_enums/server-names.enum';

@Component({
    selector: 'app-mobile-footer',
    templateUrl: './mobile-footer.component.html',
    styleUrls: ['./mobile-footer.component.scss'],
})
export class MobileFooterComponent implements OnInit {
    configurationServer$: Observable<ConfigurationServerInterface> = this.store.select(ConfigurationState.getConfigurationServer);

    currentYear = new Date().getFullYear();

    serverNamesEnum = ServerNamesEnum;

    constructor(private store: Store) {}

    ngOnInit(): void {}
}
