import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { DevicesState } from '../../device-dashboard/_store/states/user-devices.state';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { Device } from '../../app-shared-elements/_interfaces/Device';
import { GroupsState } from '../../groups/_store/states/groups.state';
import { Group } from '../../app-shared-elements/_interfaces/Group';
import { GetAllGroups } from '../../groups/_store/actions/groups.actions';
import { DeleteMobileDevice, InitMobileDevices, UpdateMobileDevices } from '../_store/actions/mobile-devices.actions';
import { MobileDevicesState } from '../_store/states/mobile-devices.state';
import { MobileDevicesInterface, SensorGroupInterface } from '../_interfaces/devices.interface';
import { AlarmTypeEnum } from '../../events/_enums/alarm.enum';
import { CreationType } from '../../app-shared-elements/_enums/registrator-sync-status.enu';
import { StateReset } from 'ngxs-reset-plugin';
import { TableState } from 'src/app/app-shared-elements/_store/states/table.state';
import { SetSkeleton } from 'src/app/app-shared-elements/_store/actions/table.actions';
import { RegistratorTypeEnum } from '../../app-shared-elements/_enums/registrator-type.enum';
import { SocketService } from '../../app-shared-elements/_services/socket.service';
import { SocketEvent } from '../../app-shared-elements/_enums/socket-event.enum';

@Component({
    selector: 'app-mobile-devices',
    templateUrl: './mobile-devices.component.html',
    styleUrls: ['./mobile-devices.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileDevicesComponent implements OnInit, OnDestroy {
    devices$: Observable<Device[]> = this.store.select(DevicesState.getDevices);
    registrators$: Observable<Device[]> = this.store.select(DevicesState.getRegistrators);
    groups$: Observable<Group[]> = this.store.select(GroupsState.getGroups);
    rows$: Observable<MobileDevicesInterface[]> = this.store.select(MobileDevicesState.getMobileDevicesArray);
    isSkeleton$: Observable<boolean> = this.store.select(TableState.getSkeleton);

    isNewShare = false;

    alarmTypeEnum = AlarmTypeEnum;
    creationType = CreationType;
    registratorTypeEnum = RegistratorTypeEnum;

    readonly MOBILE_REGISTRATOR_ICON_PATH = './assets/design/icons/devices/mobile-registrator.svg';
    readonly VIRTUAL_REGISTRATOR_ICON_PATH = './assets/design/icons/devices/virtual.svg';
    readonly SIGNAL_ICON_PATH = './assets/design/icons/devices/mobile-signal.svg';
    readonly BATTERY_ICON_PATH = './assets/design/icons/devices/mobile-battery.svg';

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private store: Store,
        private socketService: SocketService,
    ) {
        this.store.dispatch(new SetSkeleton(true));

        this.registrators$.pipe(first((registrators) => !!registrators && !!registrators.length)).subscribe(async (registrators) => {
            await this.store.dispatch(new GetAllGroups(registrators.map((r) => r.id))).toPromise();
        });

        this.registrators$.pipe(takeUntil(this.destroy)).subscribe(async (registrators) => {
            if (!registrators || !registrators.length) {
                this.store.dispatch(new SetSkeleton(false));
                return;
            }

            if (registrators.length !== this.store.selectSnapshot(MobileDevicesState.getMobileDevicesArray).length && this.isNewShare) {
                await this.store.dispatch(new GetAllGroups(registrators.map((r) => r.id))).toPromise();
                await this.store.dispatch(new InitMobileDevices()).toPromise();
                this.isNewShare = false;
            }
        });

        this.groups$.pipe(first((groups) => !!groups && !!groups.length)).subscribe(async (groups) => {
            await this.initRows();
            this.updateSubs();
        });

        this.socketService.on(SocketEvent.DYNAMIC_DEVICE_DELETE, async (id: string) => {
            await this.store.dispatch(new DeleteMobileDevice(id)).toPromise();
        });

        this.socketService.on(SocketEvent.NEW_SHARE, async () => {
            this.isNewShare = true;
        });
    }

    async ngOnInit(): Promise<void> {}

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
        this.store.dispatch(new StateReset(MobileDevicesState));
    }

    private updateSubs(): void {
        this.devices$.pipe(takeUntil(this.destroy)).subscribe(async (devices) => {
            if (!devices || !devices.length) {
                return;
            }

            this.updateData(devices);
        });
    }

    private async initRows(): Promise<void> {
        if (!this.store.selectSnapshot(GroupsState.getGroups) || !this.store.selectSnapshot(GroupsState.getGroups).length) {
            return;
        }

        await this.store.dispatch(new InitMobileDevices()).toPromise();
        this.store.dispatch(new SetSkeleton(false));
    }

    private updateData(devices: Device[]): void {
        this.store.dispatch(new UpdateMobileDevices(devices));
    }

    trackByRegistratorId(index, registrator: MobileDevicesInterface): string {
        return registrator ? registrator.registrator.id : '';
    }

    trackBySensorId(index, sensor: Device): string {
        return sensor ? sensor.id : '';
    }

    trackByVariableId(index, variable: SensorGroupInterface): string {
        return variable ? variable.variableId : '';
    }
}
