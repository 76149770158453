import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Pagination, Params, ParamsFilterForClient } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { SelectOptionInterface } from 'src/app/app-shared-elements/_interfaces/select-option.interface';
import { TableService } from 'src/app/app-shared-elements/_services/table.service';
import { TechnologicLogRowInterface } from 'src/app/journals/_interfaces/technologic-log-row.interface';
import { GetTechnologicEventsRows, SetTechnologicFilter } from 'src/app/journals/_store/actions/technologic-events.action';
import { TechnologicEventsState } from 'src/app/journals/_store/states/technologic-events.state';
import { MobileCardInterface } from '../_interfaces/mobile-card.interface';
import { InitMobileEventCards } from '../_store/actions/mobile-events.actions';
import { MobileEventsState } from '../_store/states/mobile-events.state';

@Component({
    selector: 'app-mobile-events',
    templateUrl: './mobile-events.component.html',
    styleUrls: ['./mobile-events.component.scss'],
})
export class MobileEventsComponent implements OnInit, OnDestroy {
    eventCards$: Observable<MobileCardInterface[]> = this.store.select(MobileEventsState.getEventCards);
    eventRows$: Observable<TechnologicLogRowInterface[]> = this.store.select(TechnologicEventsState.getEvents);
    params$: Observable<Params> = this.store.select(TechnologicEventsState.getFilter);

    FILTER_ICON_PATH = './assets/design/icons/mobile/filter.svg';

    customCountOption: SelectOptionInterface<number, string, number>;

    isShowFilter: boolean;

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private store: Store,
        private tableService: TableService,
    ) {
        this.eventRows$.pipe(takeUntil(this.destroy)).subscribe((rows) => {
            this.store.dispatch(new InitMobileEventCards(rows));
            const params = this.store.selectSnapshot(TechnologicEventsState.getFilter);
            this.customCountOption = this.tableService.counts.find((option) => params.pagination && option.key === params.pagination.itemsPerPage) || this.tableService.counts[2];
        });
    }

    async ngOnInit(): Promise<void> {
        await this.store.dispatch(new GetTechnologicEventsRows()).toPromise();
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }

    async changeFilter(filters: ParamsFilterForClient[]): Promise<void> {
        const params: Params = this.store.selectSnapshot(TechnologicEventsState.getFilter);

        this.store.dispatch(new SetTechnologicFilter({ ...params, filter: filters }));
    }

    async changePage(data: Pagination): Promise<void> {
        const params: Params = this.store.selectSnapshot(TechnologicEventsState.getFilter);
        localStorage.setItem('itemsPerPageLog', JSON.stringify(data.itemsPerPage));
        this.store.dispatch(new SetTechnologicFilter({ ...params, pagination: data }));
    }
}
